tarteaucitron.init({
    "bodyPosition": "bottom", /* or top to bring it as first element for accessibility */

    "hashtag": "#cookies", /* Ouverture automatique du panel avec le hashtag */
    "cookieName": "tarteaucitron", /* Cookie name */

    "orientation": "bottom", /* Banner position (top - bottom) */

    "groupServices": false, /* Group services by category */
    "serviceDefaultState": "wait", /* Default state (true - wait - false) */

    "showAlertSmall": false, /* Show the small banner on bottom right */
    "cookieslist": true, /* Show the cookie list */

    "closePopup": false, /* Show a close X on the banner */

    "showIcon": false, /* Show cookie icon to manage cookies */
    //"iconSrc": "", /* Optionnal: URL or base64 encoded image */
    "iconPosition": "BottomRight", /* BottomRight, BottomLeft, TopRight and TopLeft */

    "adblocker": false, /* Show a Warning if an adblocker is detected */

    "DenyAllCta" : true, /* Show the deny all button */
    "AcceptAllCta" : true, /* Show the accept all button when highPrivacy on */
    "highPrivacy": false, /* HIGHLY RECOMMANDED Disable auto consent */

    "handleBrowserDNTRequest": false, /* If Do Not Track == 1, disallow all */

    "removeCredit": false, /* Remove credit link */
    "moreInfoLink": true, /* Show more info link */

    "useExternalCss": false, /* If false, the tarteaucitron.css file will be loaded */
    "useExternalJs": false, /* If false, the tarteaucitron.js file will be loaded */

    //"cookieDomain": ".my-multisite-domaine.fr", /* Shared cookie for multisite */

    "readmoreLink": "", /* Change the default readmore link */

    "mandatory": true, /* Show a message about mandatory cookies */
    "mandatoryCta": true /* Show the disabled accept button when mandatory on */
});

tarteaucitron.user.gtagUa = 'G-D24CTT9WSS';
tarteaucitron.user.gtagMore = function () {
};
(tarteaucitron.job = tarteaucitron.job || []).push('gtag');

tarteaucitron.user.facebookpixelId = '1029160548451694';
tarteaucitron.user.facebookpixelMore = function () {
};
(tarteaucitron.job = tarteaucitron.job || []).push('facebookpixel');

(tarteaucitron.job = tarteaucitron.job || []).push('tawk');
(tarteaucitron.job = tarteaucitron.job || []).push('global');

